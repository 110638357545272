import types from "../action-types";
import Backendless from "backendless";

export const editSetting = (settingData, currentUser) => async (dispatch) => {
  const { currentPwd, newPwd } = settingData;
  const CLOUD_API_KEY = process.env.REACT_APP_CLOUD_API_KEY;
  const userToken = Backendless.getCurrentUserToken();
  try {
    const { body } = await Backendless.Request.send(
      `${Backendless.serverURL}/${Backendless.applicationId}/${CLOUD_API_KEY}/users/verifypassword`,
      "POST",
      {
        "user-token": userToken,
        "Content-Type": "application/json",
      },
      JSON.stringify({ password: currentPwd })
    );
    const decoder = new TextDecoder();
    const { valid } = JSON.parse(decoder.decode(body));
    if (!valid) throw new Error("Current password is incorrect");
    currentUser.password = newPwd;
    await Backendless.UserService.update(currentUser);
    const _currentUser = await Backendless.UserService.login(
      currentUser.email,
      newPwd,
      true
    );
    dispatch({
      type: types.UPDATE_USER,
      payload: _currentUser,
    });
    dispatch({
      type: types.success,
      payload: "Password changed!",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.warning,
      payload: `${err.message}(code:${err.code})`,
    });
  }
};

export const isValidLogin = () => async (dispatch) => {
  let res = false;
  try {
    dispatch({
      type: types.loading,
      payload: "Wait...",
    });
    res = await Backendless.UserService.isValidLogin();
    if (!res) throw new Error("inValid");
    const user = res ? await Backendless.UserService.getCurrentUser() : null;
    let payload = { isAuthenticated: res, currentUser: user };
    dispatch({
      type: types.IS_VALID_LOGIN,
      payload: payload,
    });
    dispatch({
      type: types.success,
      payload: "Valid user",
    });
  } catch (err) {
    dispatch({
      type: types.AUTH_ERROR,
    });
    console.log("err", err);
    dispatch({
      type: types.info,
      payload: "Please Sign in...",
    });
    res &&
      dispatch({
        type: types.error,
        payload: `Error:${err.message}(code:${err.code})`,
      });
  }
};

// register
export const register =
  (name, userType, email, password) => async (dispatch) => {
    try {
      dispatch({
        type: types.loading,
        payload: "Registering...",
      });
      let user = new Backendless.User();
      user.email = email;
      user.password = password;
      user.name = name;
      user.user = userType;
      const currentUser = await Backendless.UserService.register(user);
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: currentUser,
      });
      dispatch({
        type: types.success,
        payload: "Register success!",
      });
    } catch (err) {
      dispatch({
        type: types.AUTH_ERROR,
      });
      dispatch({
        type: types.warning,
        payload: `${err.message}(code:${err.code})`,
      });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Logging in...",
    });
    const currentUser = await Backendless.UserService.login(
      email,
      password,
      true
    );
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: currentUser,
    });
    dispatch({
      type: types.success,
      payload: "Logged in",
    });
  } catch (err) {
    dispatch({
      type: types.AUTH_ERROR,
    });
    dispatch({
      type: types.warning,
      payload: `${err.message}(code:${err.code})`,
    });
  }
};

// // Logout
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Logging out...",
    });
    let res = await Backendless.UserService.logout();
    console.log("logout res => ", res);
    dispatch({
      type: types.info,
      payload: "Logged out",
    });
    dispatch({
      type: types.LOGOUT,
    });
  } catch (err) {
    dispatch({
      type: types.error,
      payload: err,
    });
    dispatch({
      type: types.AUTH_ERROR,
    });
  }
};

// user Setting
export const userSetting = (updateUser) => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Wait...",
    });
    console.log(updateUser);
    const resUserData = Backendless.UserService.update(updateUser);
    console.log("resUserData", resUserData);
    dispatch({
      type: types.UPDATE_USER,
      payload: updateUser,
    });
    dispatch({
      type: types.success,
      payload: "Profile Updated Successfully!",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.error,
      payload: err,
    });
  }
};

// forgot password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Wait...",
    });
    Backendless.UserService.restorePassword(email);
    dispatch({
      type: types.success,
      payload: "Forgot password request sent successfully!",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.error,
      payload: err,
    });
  }
};

// send the expired schoarship notification email
export const sendExpiredNotification = (data) => async (dispatch) => {
  const { email, scholarshipName, deadline } = data;
  try {
    const templateName = "Scholarship deadline notification template";

    const emailEnvelope = new Backendless.Messaging.EmailEnvelope();
    emailEnvelope.setTo([email]);

    const templateValues = {
      scholarship_name: scholarshipName,
      deadline: deadline,
      app_name: "Thescholarshipsystem",
    };

    Backendless.Messaging.sendEmailFromTemplate(
      templateName,
      emailEnvelope,
      templateValues
    );
  } catch (error) {
    console.log(error);
  }
};

// send the announcement date notification email
export const sendAnnouncementNotification = (data) => async (dispatch) => {
  const { email, scholarshipName, announcement } = data;
  try {
    const templateName = "Announcement Date notification template";

    const emailEnvelope = new Backendless.Messaging.EmailEnvelope();
    emailEnvelope.setTo([email]);

    const templateValues = {
      scholarship_name: scholarshipName,
      announcement: announcement,
      app_name: "Thescholarshipsystem",
    };

    Backendless.Messaging.sendEmailFromTemplate(
      templateName,
      emailEnvelope,
      templateValues
    );
  } catch (error) {
    console.log(error);
  }
};
