import types from "../action-types";
import Backendless from "backendless";

// READ
export const readSch = () => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Loading Data...",
    });

    const ownerId = await Backendless.LocalCache.get("current-user-id");
    let queryBuilder = await Backendless.DataQueryBuilder.create();
    queryBuilder.setWhereClause(`ownerId='${ownerId}'`);
    const totalScholarshipsLength = await Backendless.Data.of(
      "Scholarshp"
    ).getObjectCount(queryBuilder);
    let totalScholarshp = [];
    let currentLength = 0;
    let firstLoop = true;
    while (currentLength < totalScholarshipsLength) {
      firstLoop && (currentLength = 0);
      queryBuilder.setSortBy(["ScholarshipName"]);
      queryBuilder.setPageSize(100);
      queryBuilder.setOffset(currentLength);

      let currentScholarshp = await Backendless.Data.of("Scholarshp").find(
        queryBuilder
      );
      totalScholarshp = totalScholarshp
        ? totalScholarshp.concat(currentScholarshp)
        : currentScholarshp;
      currentLength = totalScholarshp?.length;
      firstLoop = false;
    }
    dispatch({
      type: types.READ_SCH,
      scholarshp: totalScholarshp,
    });
    dispatch({
      type: types.success,
      payload: "All Data Loaded Successfully!",
    });
  } catch (err) {
    dispatch({
      type: types.error,
      payload: err,
    });
  }
};

// CREATE
export const createSch = (newData) => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Wait...",
    });
    const res = await Backendless.Data.of("Scholarshp").save(newData);
    dispatch({
      type: types.CREATE_SCH,
      scholarshp: res,
    });
    dispatch({
      type: types.success,
      payload: "Created Successfully!",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.error,
      payload: err,
    });
  }
};

// UPDATE
export const updateSch = (newData, objectId) => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Wait...",
    });
    const res = await Backendless.Data.of("Scholarshp").save(newData);
    dispatch({
      type: types.UPDATE_SCH,
      scholarshp: res,
      id: objectId,
    });
    dispatch({
      type: types.success,
      payload: "Updated Successfully!",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.error,
      payload: err,
    });
  }
};

// DELETE
export const deleteSch = (objectId) => async (dispatch) => {
  try {
    dispatch({
      type: types.loading,
      payload: "Wait...",
    });
    Backendless.Data.of("Scholarshp").remove({ objectId: objectId });
    dispatch({
      type: types.DELETE_SCH,
      id: objectId,
    });
    dispatch({
      type: types.success,
      payload: "Deleted Successfully!",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.error,
      payload: err,
    });
  }
};
