import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Space,
  Checkbox,
} from "antd";
import { toEditor } from "utils/changer";
import { autoRenewOptions, placeholderURL } from "data/addSchConstants";
import { useEffect, useState } from "react";
const { TextArea } = Input;

const DuplicateForm = ({ user, handleOk, handleCancel, loading, schData }) => {
  const [children, setChildren] = useState([]);
  const [student, setStudent] = useState(schData.Student);

  useEffect(() => {
    user.children && setChildren(user.children.split(","));
  }, [user]);

  const onSubmit = (values) => {
    if (user.user === "Parent") {
      handleOk({
        ...values,
        Student: student,
      });
    } else {
      handleOk({
        ...values,
        Student: user.name,
      });
    }
  };

  const handleChildSelect = (val) => {
    setStudent(val);
  };

  return (
    <Form
      labelCol={{
        span: 13,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      style={{
        maxWidth: 1000,
      }}
      onFinish={(values) => onSubmit(values)}
      autoComplete="off"
      fields={toEditor(schData)}
    >
      <Form.Item
        label="Scholarship Name"
        name="ScholarshipName"
        rules={[
          {
            required: true,
          },
          {
            type: "text",
            warningOnly: true,
          },
          {
            type: "string",
            min: 1,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="ScholarshipApplicationURL"
        label="Scholarship Application URL"
        rules={[
          {
            required: true,
          },
          {
            type: "url",
            warningOnly: true,
          },
          {
            type: "string",
            min: 6,
          },
        ]}
      >
        <Input placeholder={placeholderURL} />
      </Form.Item>
      <Form.Item
        name="ScholarshipURL"
        label="Scholarship URL"
        rules={[
          {
            required: true,
          },
          {
            type: "url",
            warningOnly: true,
          },
          {
            type: "string",
            min: 6,
          },
        ]}
      >
        <Input placeholder={placeholderURL} />
      </Form.Item>

      <Form.Item
        label="Reward Amount"
        name="RewardAmmount"
        rules={[
          {
            required: true,
          },
          {
            type: "number",
            warningOnly: true,
          },
          {
            type: "number",
            min: 0,
          },
        ]}
      >
        <InputNumber className="width-100" />
      </Form.Item>

      <Form.Item
        label="Student Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {user.user === "Parent" ? (
          <Select value={student} onSelect={(val) => handleChildSelect(val)}>
            {children.map((child, index) => {
              return (
                <Select.Option key={index} value={child}>
                  {child}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <Input disabled={true} value={user.name} />
        )}
      </Form.Item>
      <Form.Item
        label="Year(s) Award for"
        name="AutoRenew"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Please select"
          options={autoRenewOptions()}
        />
      </Form.Item>
      <Form.Item label="Application Status" name="Status">
        <Select>
          <Select.Option value="Not Yet Started">Not Yet Started</Select.Option>
          <Select.Option value="In Process">In Process</Select.Option>
          <Select.Option value="Submitted">Submitted</Select.Option>
          <Select.Option value="Complete">Complete</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Application Opens Date" name="ScholarshipOpen">
        <DatePicker className="width-100" format={"YYYY-MM-DD"} />
      </Form.Item>
      <Form.Item
        label="Application Deadline"
        name="Deadline"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <DatePicker className="width-100" format={"YYYY-MM-DD"} />
      </Form.Item>
      <Form.Item label="Winner Announcement Date" name="Announcement">
        <DatePicker className="width-100" format={"YYYY-MM-DD"} />
      </Form.Item>
      <Form.Item label="Scholarship Won?" name="Won">
        <Select>
          <Select.Option value="Not Yet">Not Yet</Select.Option>
          <Select.Option value="No">No</Select.Option>
          <Select.Option value="Yes">Yes</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="GPA Requirement"
        name="GPA Requirement..."
        rules={[
          {
            type: "text",
            warningOnly: true,
          },
        ]}
      >
        <Input placeholder="GPA Requirement..." />
      </Form.Item>
      <Form.Item label="Reminder Date?" name="ReminderDate">
        <Select>
          <Select.Option value="">None</Select.Option>
          <Select.Option value="1 Day Ahead">1 Day Ahead</Select.Option>
          <Select.Option value="7 Days Ahead">7 Days Ahead</Select.Option>
          <Select.Option value="14 Days Ahead">14 Days Ahead</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Should I reapply in following year(s)?"
        name="Reapply"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item label="Resume required?" name="Resume" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item label="Video required?" name="Video" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Transcript required?"
        name="Transcript"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Nomination required?"
        name="Nomination"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Proof of College acceptance required?"
        name="CollegeAcceptProof"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item label="Award Ceremony" name="AwardCeremony">
        <DatePicker className="width-100" format={"YYYY-MM-DD"} />
      </Form.Item>

      <Form.Item
        label="Headshot required?"
        name="Headshot"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item label="Money sent to" name="SentTo">
        <Select>
          <Select.Option value="">None</Select.Option>
          <Select.Option value="Student">Student</Select.Option>
          <Select.Option value="University">University</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Essays required?" name="Essays" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item label="Essays Note" name="EssayNote">
        <TextArea rows={2} placeholder="Essay note ..." />
      </Form.Item>
      <Form.Item
        label="Letter of recommendation required?"
        name="LetterOfRecommendation"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Letter of recommendation Note:"
        name="LetterOfRecommendationNote"
      >
        <TextArea rows={2} placeholder="Note ..." />
      </Form.Item>
      <Form.Item label="Description:" name="Description">
        <TextArea rows={2} placeholder="Note ..." />
      </Form.Item>
      <Form.Item label="Note" name="Note">
        <TextArea rows={2} />
      </Form.Item>
      <Space className="content-flex-end">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="login-form-button"
          >
            Save
          </Button>
        </Form.Item>
        <Form.Item>
          <Button className="login-form-button" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
export default DuplicateForm;
