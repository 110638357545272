import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AntdTable from "components/AntdTable";
import ControlsBar from "components/ControlsBar";
import { columns } from "data/antdTableColumns";
import { logout, deleteSch, readSch } from "store";
import moment from "moment";
import { searchFilter } from "utils/search";

export default function Content(props) {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.scholarshp);
  const { handleEditorSch, handleDuplicateSch } = { ...props };

  const onLogout = () => {
    dispatch(logout());
  };

  const onRefresh = () => dispatch(readSch());
  // AntdTable
  const onDelete = (id) => {
    dispatch(deleteSch(id));
  };
  const onLink = (targetUrl) => {
    targetUrl && window.open(targetUrl, "_blank");
  };
  const arrColumns = columns(
    onLink,
    handleEditorSch,
    onDelete,
    handleDuplicateSch
  );

  // declare codes for search
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(tableData);
  }, [tableData]);
  const onSearch = () => {
    const filtered = searchFilter(tableData, searchText, moment);
    setFilteredData(filtered);
  };
  useEffect(() => {
    onSearch();
  }, [searchText]);

  return (
    <div className="container-center">
      <ControlsBar
        {...props}
        allData={tableData}
        onLogout={onLogout}
        onSearch={onSearch}
        onRefresh={onRefresh}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <AntdTable tableData={filteredData} arrColumns={arrColumns} />
    </div>
  );
}
