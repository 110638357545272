import { Modal } from "antd";
import { useState } from "react";
import { toPrimitive } from "utils/changer";
import { createSch } from "store";
import { useDispatch } from "react-redux";
import DuplicateForm from "components/DuplicateForm";

const DuplicateModal = ({
  user,
  openEditor,
  setOpenEditor,
  title,
  schData,
  setSchData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleOk = (values) => {
    const objectId = schData?.objectId ? schData?.objectId : false;
    const reminder = !!values.ReminderDate ? true : false;
    const reqData = toPrimitive(values, objectId, reminder);

    dispatch(createSch(reqData));
    setSchData({});
    setLoading(true);
    setTimeout(() => {
      setOpenEditor(false);
      setLoading(false);
    }, 500);
  };

  const handleCancel = () => {
    setSchData({});
    setOpenEditor(false);
  };

  return (
    <>
      <Modal
        title={title}
        open={openEditor}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <DuplicateForm
          user={user}
          handleOk={handleOk}
          loading={loading}
          handleCancel={handleCancel}
          schData={schData}
        />
      </Modal>
    </>
  );
};

export default DuplicateModal;
